import Cookies from "cookies-js";
import React from "react";

class ProductStyle {

  static addCss(product) {

    return;

    var addProduct = ''

    if (product == 'aipro') {
      addProduct = 'aipro';
    }

    if (!addProduct) {
      return;
    }

    const link = document.createElement('link');
    link.href = `/css/${addProduct}.css`; // Update this path according to your actual build path
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.onload = () => {
      console.log('CSS dynamically loaded and applied.');
    };
    link.onerror = () => {
      console.error('Error loading CSS.');
    };
    document.head.appendChild(link);

  }

  static getLogo(product) {

    if (product === 'aipro' || product === 'bonfire') {
      return <img src="/img/ai-pro-logo.png" className={"AI Pro"} width={100}/>
    }

    return <img src="/img/boostlogo.png" className={"boostlogo"}/>

  }

  static checkAndAddStyle() {

    return;

    const product = Cookies.get('login.product');

    if (!product) {
      return;
    }

    ProductStyle.addCss(product);

  }

  static removeCookie() {
    Cookies.expire('login.product');
  }

}

export default ProductStyle;
