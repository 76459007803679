import React, {useRef, useState, useEffect} from 'react'
import DocumentTitle from "react-document-title"
import queryString from 'query-string';
import intl from 'react-intl-universal';
import Translations from "../../Core/Translations";
import Recaptcha from "../../Components/Recaptcha";
import ProductStyle from "../../Components/ProductStyle";

var voiceboxer = require("../../Core/Libs/voiceboxer-api-client");

var Bs = require('react-bootstrap')

export default function ResetPassword(props) {

    const [password, setPassword] = useState("")
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
      ProductStyle.checkAndAddStyle();
    }, []);

    const handleChangeSubmit = (e) => {

        e.preventDefault();

        let params = queryString.parse(props.location.search)

        voiceboxer.api.post('/users/password/change/' + params["token"], { password }, (err, response) => {
            if (err) setError(err)
            else setSuccess(true)

        });

    }

    const renderError = () => {

        if(!error) return;

        var message = error.message;
        if(error.statusCode === 401 || error.statusCode === 404 || !error.statusCode) message = Translations.get('Error: Invalid token for password reset. Please ensure that you are using the latest password reset link or request a new one.');
        else if(error.body && error.body.message) message = error.body.message;

        return (
            <Bs.Alert bsStyle='danger'>
                {message}
            </Bs.Alert>
        );
    }

    const renderContent = () => {
        if (success) {
            return (
                <Bs.Panel className='panel-body'>
                    <div className='form-header'>
                        <div className='shapes-container'>
                            <div className="shape"/>
                            <div className="under-shape"/>
                        </div>
                        <h1>{intl.get('Password changed')}</h1>
                    </div>
                    <div style={{padding: '10px 20px'}}>
                        <p>{intl.get('You have successfully changed your password.')}</p>
                        <div className='text-align-center'>
                            <span>{intl.get('Want to')} <a href='/login'>{intl.get('Log in')}</a>{'?'}</span>
                        </div>
                    </div>
                </Bs.Panel>
            );
        } else {
            return (
                <Bs.Panel className='panel-body'>
                        <div className='form-header'>
                            <a href={window.config.rename.boostEventsWebsiteUrl}>
                                <img src="/img/boostlogo.png" className={"boostlogo"}/>
                            </a>
                            <h1>{intl.get("Reset password")}</h1>
                        </div>
                        {renderError()}
                        <form onSubmit={(e) => { handleChangeSubmit(e) }}>
                            <Bs.FormGroup>
                                <Bs.FormControl
                                    type='password'
                                    placeholder={intl.get('New Password')}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }} autoFocus />
                            </Bs.FormGroup>
                            <Bs.FormGroup className='text-align-right'>
                                <Bs.Button type='submit' bsStyle='primary'>{intl.get('Change')}</Bs.Button>
                            </Bs.FormGroup>
                        </form>
                        <div className='text-align-center signUpBlock'>
                            <span>{intl.get('Remember your password?')} <a href='/login'>{intl.get('Log in')}</a>{'.'}</span>
                        </div>
                </Bs.Panel>
            );
        }
    }

    return (
        <DocumentTitle title='Reset Password'>
            <Bs.Grid className='single-panel-container'>
                <Bs.Row className='panel-row'>
                    <Bs.Col className='form-container'>
                        {renderContent()}
                    </Bs.Col>
                </Bs.Row>
            </Bs.Grid>
        </DocumentTitle>
    )

}
