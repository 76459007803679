import React, { useState, useRef, useEffect } from 'react';
import intl from 'react-intl-universal';
import Recaptcha from "../../../../Components/Recaptcha";
import Cookies from "cookies-js"
import Translations from "../../../../Core/Translations";
import queryString from "query-string";

var voiceboxer = require("../../../../Core/Libs/voiceboxer-api-client");
var Bs = require('react-bootstrap')


export default function Register(props) {

    const isArabic = Cookies.get("lang") == "ar";

    const [organization, setOrgnization] = useState(null)
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [autoPassword, setAutoPassword] = useState(!!props.eventMode)
    const [privacyPolicy, setPrivacyPolicy] = useState(true)
    const [error, setError] = useState(null)
    const [organizationDisabled, setOrganizationDisabled] = useState(false);

    const token = useRef(null);
    const refreshToken = useRef(() => {});

    useEffect(() => {

        const params = queryString.parse(props.location.search)
        if (params['p']) {
            setOrganizationDisabled(true);
        }

    }, []);

    const handleSubmit = (e) => {

        e.preventDefault();

        if(!isNaN(email.charAt(0))) {
            props.passError({
                message: "Your email address needs to start with a letter. Please try again.",
                statusCode: 422
            })
            return
        }

        if(/^\s/.test(name)) {
            props.passError({
                message: "The name you entered is invalid. Please try again.",
                statusCode: 422
            })
            return
        }

        if (privacyPolicy) {

            props.signUpSubmitted({
                name,
                email,
                password,
                token: token.current,
                auto_password: autoPassword,
                privacy_policy: privacyPolicy,
                organization
            }, refreshToken.current);
        } else {
            props.passError({
                message: intl.get('Please accept the privacy policy'),
                statusCode: 422
            });
        }

    }

    return (
        <form className={`${isArabic && "arabic-interface"}`} onSubmit={(e) => { handleSubmit(e) }}>
            <Bs.FormGroup>
                <Bs.FormControl
                    type='text'
                    placeholder={intl.get('Name')}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    autoFocus
                />
            </Bs.FormGroup>
            {!organizationDisabled ? <Bs.FormGroup>
                <Bs.FormControl
                    type='text'
                    placeholder={autoPassword ? intl.get('Organization') : intl.get('Organization (optional)')}
                    value={organization}
                    onChange={(e) => {
                        setOrgnization(e.target.value)
                    }}
                />
            </Bs.FormGroup> : null}
            <Bs.FormGroup>
                <Bs.FormControl
                    type='text'
                    placeholder={intl.get('E-mail')}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value.trim())
                    }}
                />
            </Bs.FormGroup>
            {!autoPassword && <Bs.FormGroup>
                <Bs.FormControl
                    type='password'
                    placeholder={intl.get('Password')}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                />
            </Bs.FormGroup> }

            <Recaptcha onToken={(recaptchaToken) => {
                token.current = recaptchaToken
            }} refreshToken={(onRefresh) => {
                refreshToken.current = onRefresh
            }} className={"captcha-container"}/>

            {autoPassword ?
                <Bs.FormGroup>
                    <Bs.Button
                        type='submit'
                        bsStyle='success'
                        className='register-button'
                        disabled={props.submitting}
                    >
                        {intl.get("Register")}
                    </Bs.Button>
                </Bs.FormGroup>
                :
                <Bs.FormGroup className='text-align-right'>
                    <Bs.Button type='submit' disabled={props.submitting} bsStyle='primary'>{intl.get("Sign up")}</Bs.Button>
                </Bs.FormGroup>
            }

            <Bs.FormGroup className={"privacy-policy-block"}>
                {Translations.get(`By clicking "create account" you agree to`)} <a href='https://boostlingo.com/privacy-policy/' target='_blank'>{intl.get("Privacy Policy")}</a>
            </Bs.FormGroup>

        </form>
    );

}
