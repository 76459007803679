import Cookies from 'cookies-js';

export default function setCookieSameSite(name, value, options) {
    Cookies.set(name, value, options);

    // manually setting the cookie with SameSite=None and Secure so it can be seen when embedded inside an IFRAME
    if (value && window.config?.cookie?.domain) {
        document.cookie = [
          name + "=" + encodeURIComponent(value),
            "path=/",
            "domain=" + encodeURIComponent(window.config.cookie.domain),
            "SameSite=none",
            "Secure"
        ].join(";");
    }
}