import {
    SET_LANGUAGE_DROPDOWN_HIDDEN,
    SET_SUPPORTED_LOCALES
} from "./constants";

export function setLanguageDropdownHidden(payload) {
    return { type: SET_LANGUAGE_DROPDOWN_HIDDEN, payload }
}

export function setSupportedLocales(payload) {
    return { type: SET_SUPPORTED_LOCALES, payload }
}
