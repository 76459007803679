import Cookies from 'cookies-js';

export default async function validateAccessApi(type, productCookie) {

    if (type === 'access') {
        const token = Cookies.get(config.accessApi.cookie);
        if (!token) {
          return false;
        }
      } else if (type === 'product') {
        const token = Cookies.get(config.accessApi.cookie);
        if (token) {
          return false;
        }
      }

      const data = {
        product: 'aipro',
        region: config.region,
        type
      };

      const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',              
              'Authorization': type === 'access' ? `${Cookies.get(config.accessApi.cookie)}` : productCookie
          },
          credentials: 'include',
          body: JSON.stringify(data)
      };

      try {
        const response = await fetch(`${config.accessApi.url}/tokens/validate`, options);
        const json = await response.json();
        if (json.status === "success") {
          document.location.reload();
          return true;
        }
      } catch (err) {
        return false
      }

      return false

}