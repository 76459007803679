import {
    SET_LANGUAGE_DROPDOWN_HIDDEN,
    SET_SUPPORTED_LOCALES
} from "./constants";

export default function LanguageDropdownReducer(state = {
    isHidden: false,
    supportedLocales: []
}, action) {

    if (action.type == SET_LANGUAGE_DROPDOWN_HIDDEN) {
        state.isHidden = action.payload
        return state
    }else if (action.type == SET_SUPPORTED_LOCALES) {
        state.supportedLocales = action.payload
        return state
    }

    return state

}