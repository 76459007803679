import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import DocumentTitle from "react-document-title"
import Loader from "react-loader"
import queryString from 'query-string';
import Cookies from 'cookies-js';
import intl from 'react-intl-universal';
import Recaptcha from "../../Components/Recaptcha";
import ProductStyle from "../../Components/ProductStyle";
import Translations from "../../Core/Translations";
import setCookieSameSite from '../../Utils/set-cookie-same-site';
import validateAccessApi from '../../Utils/validate';

var voiceboxer = require("../../Core/Libs/voiceboxer-api-client");
var config = window.config;

var Bs = require('react-bootstrap');

export default function EnterPassword(props) {

    const history = useHistory()

    const [error, setError] = useState(null)
    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState(atob(queryString.parse(props.location.search)["e"]))
    const [product, setProduct] = useState("");

    const token = useRef(null);
    const refreshToken = useRef(() => {});

    useEffect(() => {

        setProductExists();

        voiceboxer.on('login', () => {
            handleLogin()
        });

        voiceboxer.on('logout', () => {
            handleLogout()
        });

        ProductStyle.checkAndAddStyle();

    }, [])

    useEffect(() => {

        if (isSubmitting) {
            login()
        }

    }, [isSubmitting])

    const setProductExists = () => {

        let params = queryString.parse(props.location.search)
        if (params["p"]) {
            setProduct(params["p"]);
        }

    }

    const handleLogin = (user) => {
        setUser(user)
        onLogin(null, user)
    }

    const handleLogout = () => {
        setUser(null)
    }

    const handleLoginSubmit = (e) => {

        e.preventDefault();
        setIsSubmitting(true)

    }

    const login = () => {

        if (!token.current) {
            return setTimeout(() => {
                login();
            }, 500);
        }

        voiceboxer.login({ email: email, password: password, token: token.current }, (err, response) => {

            if (err) {
                refreshToken.current(err.captchaVer);
                onLogin(err, null);
            } else {
                if (typeof PasswordCredential !== 'undefined') {
                    let credentialForm = document.getElementById('loginForm');
                    let credential = new PasswordCredential({
                        id: email,
                        password: password
                    });
                    navigator.credentials.store(credential)
                    .catch(exc => console.error("Can't store credentials:", exc));
                }
            }

        });

    }

    const onLogin = async (err) => {

        if (err) {
              setError(isSubmitting ? err : null)
              setIsSubmitting(false)
              setIsLoading(false)
        } else {
            /*
                Cookies library does not support SameSite settings, so we're manually doing what it did to set the Cookie client side (in voiceboxer API library)
                and setting SameSite=none
                This is needed for our Bonfire IFRAME to be loaded inside the browser extension.

                TODO: This cookie must be set correctly with SameSite=none credentials in voiceboxer.login()
            */
            setCookieSameSite(config.cookie.name, JSON.stringify(voiceboxer.token));
            setCookieSameSite("__vb.token", JSON.stringify(voiceboxer.token));

            var data = Cookies.get('login.data');

            if (data) {
                data = JSON.parse(atob(data))
                ProductStyle.removeCookie();
                if (data.successRedirect) {
                    const token = Cookies.get(config.cookie.name);                    
                    if (token) {
                        const productCookie = JSON.parse(token);
                        await validateAccessApi("product", productCookie.access_token);
                    }
                    document.location.href = data.successRedirect;
                }
            }
        }
    }

    const backToEmail = () => {
        document.location.href = `/login?p=${product}`;
    }

    const renderError = () => {

        if(!error) return;

        var message = error.message;
        if(error.statusCode === 401 || error.statusCode === 404 || !error.statusCode) {
            message = intl.get('Invalid credentials or account does not exist').d('Invalid credentials or account does not exist');
        }
        else if(error.body && error.body.message) message = error.body.message;

        return (
            <Bs.Alert bsStyle='danger'>
                {message}
            </Bs.Alert>
        );
    }

    return (
        <DocumentTitle title='Login'>
            <Loader loaded={!isLoading}>
                <Bs.Grid className='single-panel-container'>
                    <Bs.Row className='panel-row'>
                        <Bs.Col className='form-container'>
                            <Bs.Panel className='panel-body'>
                                <div className='form-header'>
                                    {ProductStyle.getLogo(product)}
                                    <h1>{intl.get("Login")}</h1>
                                </div>
                                {renderError()}
                                <form id="loginForm" onSubmit={(e) => { handleLoginSubmit(e) }} autoComplete={"current-password"}>
                                    <Bs.FormGroup>
                                        <div style={{marginBottom: 15, textAlign: "left", fontSize: 11}}>
                                            {intl.get('Logging in as').d('Logging in as')}: <strong>{email}</strong>
                                        </div>
                                        <input type={"hidden"} name={"username"} value={email} autoComplete={"username"}/>
                                        <Bs.FormControl type='password' name={"password"} autoFocus placeholder={intl.get('Password')} value={password} onChange={(e) => {
                                            setPassword(e.target.value)
                                        }} autoComplete={"current-password"}/>
                                    </Bs.FormGroup>

                                    <Recaptcha onToken={(recaptchaToken) => {
                                        token.current = recaptchaToken
                                    }} refreshToken={(onRefresh) => {
                                        refreshToken.current = onRefresh
                                    }} className={"captcha-container"}/>

                                    <Bs.FormGroup className='text-align-center'>
                                        <Bs.Button type='submit' disabled={isSubmitting} bsStyle='primary'>{intl.get('Login')}</Bs.Button>
                                    </Bs.FormGroup>
                                    <div className={"forgot-back-block"}>
                                        <span><a href='javascript:void(0)' onClick={() => backToEmail()} className={"boldLink gray"}>{Translations.get('Return to email')}</a></span>
                                        <span className={"flexRight"}><a href={`/forgot?p=${product}`} className={"boldLink signup-link"}>{intl.get('Forgot password')}</a></span>
                                    </div>
                                </form>
                                <div className={'signUpBlock'}>
                                    <span>{Translations.get('Do not have an account?')} </span>
                                    <a href={`${config.access.url}/sign-up-1?p=${product}`} className="signup-link">{intl.get('Sign up')}</a>
                                </div>
                            </Bs.Panel>
                        </Bs.Col>
                    </Bs.Row>
                </Bs.Grid>
            </Loader>
        </DocumentTitle>
    )

}
