import React, { useCallback, useEffect, useState } from 'react';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import RecaptchaV2 from "react-google-recaptcha";

function Recaptcha(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('captcha');
    props.onChange(token);

  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return null;
};

export default function(props) {

  const [isLoading ,setIsLoading] = useState(false);
  const [useVersion,setUseVersion] = useState("v3");

  useEffect(() => {

    if (isLoading) {
      setIsLoading(false);
    }

  }, [isLoading]);

  const refreshToken = (version) => {

    if (version) {
      setUseVersion(version);
    }
    setIsLoading(true);
  };

  useEffect(() => {
    props.refreshToken(refreshToken);
  }, []);

  return <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha.v3}>
    {!isLoading ? <Recaptcha onChange={(token) => {
      props.onToken(token + "|||v3");
    }}/> : null}
    {!isLoading && useVersion == "v2" ? <div className={props.className}>
      <RecaptchaV2 sitekey={config.recaptcha.v2}
                   onChange={(token) => {
                     props.onToken(token + "|||v2");
                   }} />
    </div> : null}
  </GoogleReCaptchaProvider>

}