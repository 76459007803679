import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter } from 'react-router-dom';
import history from './Core/Navigation/History';
import Routes from './Core/Navigation/Routes';
import intl from 'react-intl-universal';
import async from 'async';
import { useDispatch } from "react-redux";
import LanguageSelectionDropdown from "./localization/language-selection-dropdown";
import _, { result } from 'lodash'
import Cookies from 'cookies-js';
import { setSupportedLocales } from "./localization/Store/actions";
import setCookieSameSite from './Utils/set-cookie-same-site';
var voiceboxer = require('./Core/Libs/voiceboxer-api-client');

function App() {

    const dispatch = useDispatch();
    const [initDone, setInitDone] = useState(false);
    const lobbyCheckInterval = useRef(null);

    useEffect(() => {
        loadLocales()
        checkForAccess()
    }, []);

    const loadLocales = () => {
      var self = this;
      const lang = new URLSearchParams(window.location.search).get("lang");
      async.waterfall([
        function (cb) {
          voiceboxer.api.get(
            "/localization-version/latest",
            function (err, version) {
              cb(err, version);
            }
          );
        },
        function (version, cb) {
          if (version) {
            if (version) {
              let currentLocale = intl.determineLocale({
                urlLocaleKey: 'lang',
                cookieLocaleKey: 'lang',
                localStorageLocaleKey: 'lang'
              });

              const windowUrl = window.location.search;
              const params = new URLSearchParams(windowUrl);
              const lang = params.get('lang');

              if (Cookies.get('lang')) {
                currentLocale = Cookies.get('lang');
              } else if (lang) {
                currentLocale = lang;
                setCookieSameSite('lang', lang, {expires: 31622400});
              } else {
                currentLocale = 'en';
                setCookieSameSite('lang', currentLocale, {expires: 31622400});
              }

              if(!!lang) {
                currentLocale = lang;
                setCookieSameSite('lang', currentLocale, {expires: 31622400});
              }
              fetch(`${config.aws.url}/versions/${version.version}/locales/${currentLocale.slice(0, 2)}.json`)
                .then((response) => response.json())
                .then(data => {
                const supportedLocales = version.languages.map(item => ({
                  name: item.name,
                  value: item.code,
                  languageAbbr: item.code,
                  flagAbbr: item.flagAbbr,
                  status: item.status,
                }));
                window.SUPPORTED_LOCALES = supportedLocales;
                intl.init({
                  currentLocale,
                  locales: {
                    [currentLocale]: data
                  }
                }).then(() => {
                  dispatch( setSupportedLocales(supportedLocales) );
                  setInitDone(true);
                });
              });
            }
          }
          cb();
        },
      ]);
    };

    // Redirect to lobby if not accessed through lobby yet
    const checkForAccess = () => {
      const widOk = Cookies.get(config.lobby.cookie + '_ok');
      var wid = Cookies.get(config.lobby.cookie);

      if (!widOk || !wid) {
        return false;
      }

      lobbyCheckInterval.current = setInterval(() => {

        wid = Cookies.get(config.lobby.cookie);
        if (wid) {
          pingLobby(wid);
        } else {
          clearInterval(lobbyCheckInterval.current);
        }

      }, 3000);

      pingLobby(wid);

      return true;
    }

    const pingLobby = (wid) => {

      fetch(`${config.lobby.api}/queue/ping?wid=${wid}`).then((response) => {
        if (response.status == 400) {
          Cookies.expire(config.lobby.cookie + '_ok');
          Cookies.expire(config.lobby.cookie);
          clearInterval(lobbyCheckInterval.current);
        }
      });

    }

    return initDone && (
      <BrowserRouter history={history}>
        <LanguageSelectionDropdown />
        <Routes />
      </BrowserRouter>)
}
export default App;
