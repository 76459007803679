import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';

function Initial() {

    const history = useHistory();
    const navigateToLogin = () => history.push('/login');

    useEffect(() => {

        setTimeout(() => {
           navigateToLogin()
        }, 2000)

    }, [])


    return (
        <div></div>
    )
}

export default Initial;