import {
    SET_IS_LOADING
} from "constants";

export default function LoginReducer(state = {
    isLoading: true,
}, action) {

    return state

}