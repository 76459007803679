import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Initial from '../../../Screens/Initial';
import Login from '../../../Screens/Login';
import EnterPassword from '../../../Screens/EnterPassword';
import LoginSuccess from '../../../Screens/LoginSuccess';
import Register from '../../../Screens/Register';
import ResetPassword from "../../../Screens/ResetPassword";
import ForgotPassword from "../../../Screens/ForgotPassword";
import EnterOTP from '../../../Screens/EnterOTP';
import UserSettings from "../../../Screens/UserSettings";

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={Initial} />
            <Route path="/login" exact component={Login} />
            <Route path="/authenticate" exact component={EnterPassword} />
            <Route path="/otp" exact component={EnterOTP} />
            <Route path="/register" exact component={Register} />
            <Route path="/reset" exact component={ResetPassword} />
            <Route path="/forgot" exact component={ForgotPassword} />
            <Route path="/success" exact component={LoginSuccess} />
            <Route path="/settings" exact component={UserSettings} />
        </Switch>
    );
}