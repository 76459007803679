import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import DocumentTitle from "react-document-title";
import Loader from "react-loader";
import queryString from 'query-string';
import Cookies from 'cookies-js';
import intl from "react-intl-universal";
import {useDispatch, useSelector} from "react-redux";
import { setLanguageDropdownHidden } from "../../localization/Store/actions";

const Bs = require('react-bootstrap');
const voiceboxer = require("../../Core/Libs/voiceboxer-api-client");

export default function UserSettings(props) {

    const history = useHistory()
    const dispatch = useDispatch()

    const supportedLocales = useSelector(state => state.languageDropdown.supportedLocales);
    const [savingInProgress, setSavingInProgress] = useState(false)
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [user, setUser] = useState(null)

    const id = useRef(null);
    const hash = useRef(null);

    useEffect(() => {

      dispatch(setLanguageDropdownHidden(true));
      getUserIdentity();
      getUserSettings();

    }, [])

    const getUserIdentity = () => {

      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);

      id.current = params.get("id");
      hash.current = params.get("hash");

    }

    const getUserSettings = () => {

      if (!id.current || !hash.current) {
        return;
      }

      voiceboxer.api.post("/users/settings", {
        id: id.current,
        hash: hash.current
      }, (err, data) => {
          if (err || data.status != "success") return;
          setUser(data.user);
          setIsLoading(false);
      });

    }

    const getLanguageByCode = (code) => {
      return supportedLocales.find((language) => {
        return language.languageAbbr == code;
      })
    }

    const changeSetting = (field, value) => {
      user[field] = value;
      setUser(user);
    }

    const saveSettings = () => {

      setSavingInProgress(true);

      voiceboxer.api.patch("/users/settings", {
        id: id.current,
        hash: hash.current,
        data: user
      }, (err, data) => {
        if (err || data.status != "success") {
          setSavingInProgress(false);
          setMessage({
            type: "error",
            text: intl.get("Error saving settings")
          })
          return;
        };

        const language = getLanguageByCode(user.language)
        Cookies.set('lang', language.value, {expires: 31622400});
        window.location.reload();

      });
    }

    const renderMessage = () => {

      if (message) {
        return <div className={`text-align-center messageContainer ${message.type}`}>
          {message.text}
        </div>;
      }

    }

    let language = intl.get("Not set");

    if (user && user.language) {
      const languageByCode = getLanguageByCode(user.language)
      if (languageByCode) {
        language = languageByCode.name;
      }
    }

    return (
        <DocumentTitle title='User settings'>
            <Loader loaded={!isLoading}>
              {user && supportedLocales ? <Bs.Grid className='single-panel-container'>
                <Bs.Row className='panel-row'>
                  <Bs.Col className='form-container'>
                    <Bs.Panel className='panel-body'>
                      <div className='form-header'>
                        <div className='shapes-container'>
                          <div className="shape"/>
                          <div className="under-shape"/>
                        </div>
                        <h1>{intl.get("User settings")}</h1>
                      </div>
                      {renderMessage()}
                      <form>
                        <Bs.FormGroup >

                          <span className={"caption"}>{intl.get("Default language")}</span>
                          <select id={'language-select-dropdown'} className={'selector dropdown'} onChange={(e) => {
                              changeSetting("language", e.target.value);
                          }} defaultValue="">
                            <option value="" disabled>
                              {language}
                            </option>
                            {supportedLocales.filter(locale => locale.status === 'published').map(locale => (
                              <option key={locale.value} value={locale.languageAbbr}>
                                {locale.name}
                              </option>
                            ))}
                          </select><br/><br/>

                          <div className='text-align-center'>
                            {!savingInProgress ? <Bs.Button onClick={() => {
                              saveSettings();
                            }} bsStyle='primary'>
                              {intl.get('Save')}
                            </Bs.Button> : <Loader className="spinner"
                                                   options={{"position": "relative", width: 3}}/> }
                          </div>
                        </Bs.FormGroup>
                      </form>
                      <div className='text-align-center graySmallLeters'>
                        {intl.get('Logged in as')}: {user.name}
                      </div>
                      <div className="powered-by">
                        <span>{intl.get("Powered by")}</span>
                        <a href={window.config.rename.boostEventsWebsiteUrl}>
                        <img src="/img/BoostEvents-logos/portal-login-logo.png" />
                        </a>
                      </div>
                    </Bs.Panel>
                  </Bs.Col>
                </Bs.Row>
              </Bs.Grid> : null}
            </Loader>
        </DocumentTitle>
    )

}