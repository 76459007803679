import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import DocumentTitle from "react-document-title"
import SignupForm from "./Views/SignUpForm";
import intl from 'react-intl-universal'
import ProductStyle from "../../Components/ProductStyle";
import queryString from "query-string";

var voiceboxer = require("../../Core/Libs/voiceboxer-api-client");
var Bs = require('react-bootstrap')

export default function Register(props) {

    const history = useHistory()

    const [error, setError] = useState(null)
    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [product, setProduct] = useState("");

    useEffect(() => {
        setProductExists();
        ProductStyle.checkAndAddStyle();
    }, []);

    const setProductExists = () => {

        let params = queryString.parse(props.location.search)
        if (params["p"]) {
            setProduct(params["p"]);
        }

    }

    const handleSignUpSubmitted = (data, refreshToken) => {

        setIsSubmitting(true);

        voiceboxer.api.post('/users/signup', data, (err, response) => {

            if(err) {
                refreshToken();
                setError(err)
                setIsSubmitting(false)
                return
            } else if (response.error) {
                refreshToken(response.captchaVer);
                setError({message: response.error, statusCode: 200})
                return setIsSubmitting(false)
            }


            setUser(response)
            setIsSubmitting(false)

        });
    }

    const passError = (error) => {
        setError(error)
    }

    const renderError = () => {

        if(!error) return null;

        var message = error.message;
        var body = error.body || {};

        if(body.errors) {
            message = Object.keys(body.errors).map(function(key, i) {
                return <p key={i}>{body.errors[key].message}</p>;
            });
        } else if(body.message) {
            message = <p>{error.body.message === 'Invalid data' ? intl.get('Invalid data') : error.body.message}</p>;
        } else if(!error.statusCode) {
            message = <p>{intl.get('Invalid Input')}</p>;
        }

        return (
            <Bs.Alert bsStyle='danger'>
                {message}
            </Bs.Alert>
        );
    }

    const renderContent = () => {

        if (user) {
            return (
                <Bs.Panel className='panel-body'>
                    <div className='form-header'>
                        <a href={window.config.rename.boostEventsWebsiteUrl}>
                            <img src="/img/boostlogo.png" className={"boostlogo"}/>
                        </a>
                        <h1>{intl.get("Thank you!")}</h1>
                    </div>
                    <p>{intl.get('Hi')} {user.name}{','}</p>
                    <p>{intl.get('Thank you for creating a VoiceBoxer account. You are now ready to speak to the world.')}</p>
                    <p>{intl.get('Join events you have been invited to or participate in public events.')}</p>
                    <div className='text-align-center signUpBlock'>
                        <span>{intl.get('You can ')} <a href={`/login?p=${product}`}>{intl.get('Log in here')}</a>{'.'}</span>
                    </div>
                </Bs.Panel>
            );
        } else {
            return (
                <Bs.Panel className='panel-body'>
                    <div className='form-header'>
                        <a href={window.config.rename.boostEventsWebsiteUrl}>
                            <img src="/img/boostlogo.png" className={"boostlogo"}/>
                        </a>
                        <h1>{intl.get("Sign up")}</h1>
                    </div>
                    {renderError()}
                    <SignupForm signUpSubmitted={handleSignUpSubmitted} submitting={isSubmitting} passError={passError} location={props.location}/>
                    <div className='text-align-center signUpBlock'>
                        <span>{intl.get('Already have an account?')} <a href={`/login?p=${product}`} className={"boldLink"}>{intl.get('Log in')}</a>{'.'}</span>
                    </div>
                </Bs.Panel>
            );
        }
    }

    return (
        <DocumentTitle title='Sign Up'>
            <Bs.Grid className='single-panel-container'>
                <Bs.Row className='panel-row'>
                    <Bs.Col className='form-container register-form'>
                        {renderContent()}
                    </Bs.Col>
                </Bs.Row>
            </Bs.Grid>
        </DocumentTitle>
    )

}
