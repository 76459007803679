import React, { useState, useRef, useEffect } from 'react'
import DocumentTitle from "react-document-title"
import queryString from 'query-string';
import intl from 'react-intl-universal';
import Recaptcha from "../../Components/Recaptcha";
import ProductStyle from "../../Components/ProductStyle";

var voiceboxer = require("../../Core/Libs/voiceboxer-api-client");
var config = window.config;

var Bs = require('react-bootstrap');

export default function ResetPassword(props) {

    const [email, setEmail] = useState("")
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)
    const [product, setProduct] = useState("");

    const token = useRef(null);
    const refreshToken = useRef(() => {});

    useEffect(() => {
        setProductExists();
        ProductStyle.checkAndAddStyle();
    }, []);

    const setProductExists = () => {

        let params = queryString.parse(props.location.search)
        if (params["p"]) {
            setProduct(params["p"]);
        }

    }

    const handleSendSubmit = (e) => {

        e.preventDefault();

        voiceboxer.api.post('/users/password/request', { email, token: token.current }, (err, response) => {

            if (err) {
                refreshToken.current();
                setError(err)
            } else if (response.error) {
                refreshToken.current(response.captchaVer);
                setError({message: response.error, statusCode: 200});
            }
            else setSuccess(true)

        });

    }

    const renderError = () => {

        if(!error) return;

        var alarmStyle = {
            display: 'block',
            position: 'fixed',
            width: '100%',
            height: '50px',
            lineHeight: '50px',
            textAlign: 'center',
            fontWeight: 'bold',
            top: 0,
            left: 0,
            backgroundColor: '#ffff00',
        }; 
        
        var message = error.message;
        if(error.statusCode === 401 || error.statusCode === 404 || !error.statusCode) message = <div style={alarmStyle}>{intl.get("There is no VoiceBoxer account associated with this email. Please enter a correct email address or create an account via our ")}<a href={`${config.access.url}/sign-up-1`}>{intl.get("signup page.")}</a></div>;
        else if(error.body && error.body.message) message = <div style={alarmStyle}>{error.body.message}</div>;

        return message
    }

    const renderContent = () => {
        if(success) {
            return (
                <Bs.Panel className='panel-body'>
                    <div className='form-header'>
                        {ProductStyle.getLogo(product)}
                        <h1>{intl.get("E-mail sent")}</h1>
                    </div>
                    <p style={{ padding: "10%" }}>{intl.get('If you have an account, we will send you an e-mail shortly.').d('If you have an account, we will send you an e-mail shortly.')}</p>
                    <div className='text-align-center signUpBlock'>
                        <span>{intl.get('Got your password?').d('Got your password?')} <a href={`/login?p=${product}`}>{intl.get('Login here').d('Login here')}</a>{'.'}</span>
                    </div>
                </Bs.Panel>
            );
        } else {
            return (
                <Bs.Panel className='panel-body'>
                    <div className='form-header'>
                        {ProductStyle.getLogo(product)}
                        <h1>{intl.get("Forgot password")}</h1>
                    </div>
                    {renderError()}
                    <form onSubmit={(e) => { handleSendSubmit(e) }}>
                        <Bs.FormGroup>
                            <Bs.FormControl type='text' placeholder={intl.get('E-mail')} autoFocus value={email ? email : ''} onChange={(e) => {
                                setEmail(e.target.value)
                            }} />
                        </Bs.FormGroup>

                        <Recaptcha onToken={(recaptchaToken) => {
                            token.current = recaptchaToken
                        }} refreshToken={(onRefresh) => {
                            refreshToken.current = onRefresh
                        }} className={"captcha-container"}/>

                        <Bs.FormGroup className='text-align-right'>
                            <Bs.Button type='submit' bsStyle='primary'>{intl.get('Send')}</Bs.Button>
                        </Bs.FormGroup>
                    </form>
                    <div className='text-align-center signUpBlock'>
                        <span>{intl.get('Remember your password?')} <a href={`/login?p=${product}`} className="signup-link">{intl.get('Log in')}</a>{'.'}</span>
                    </div>
                </Bs.Panel>
            );
        }
    }

    return (
        <DocumentTitle title='Forgot Password'>
            <Bs.Grid className='single-panel-container'>
                <Bs.Row className='panel-row'>
                    <Bs.Col className='form-container'>
                        {renderContent()}
                    </Bs.Col>
                </Bs.Row>
            </Bs.Grid>
        </DocumentTitle>
    )

}
