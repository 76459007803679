import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DocumentTitle from "react-document-title";
import Loader from "react-loader";
import queryString from "query-string";
import Cookies from "cookies-js";
import OTPInput from "./Component/OTPInput";
import intl from 'react-intl-universal';

var voiceboxer = require("../../Core/Libs/voiceboxer-api-client");
var config = window.config;

var Bs = require("react-bootstrap");

export default function EnterOTP(props) {
  const history = useHistory();

  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otp, setOtp] = useState("");
  let [disableResend, setDisableResend] = useState(0);
  const [isShowMessage, setIsShowMessage] = useState(0);

  useEffect(() => {
    voiceboxer.on("login", () => {
      handleLogin();
    });

    voiceboxer.on("logout", () => {
      handleLogout();
    });
  }, []);

  useEffect(() => {
    if (isSubmitting) {
      login();
    }
  }, [isSubmitting]);

  const handleLogin = (user) => {
    setUser(user);
    onLogin(null, user);
  };

  const handleLogout = () => {
    setUser(null);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  };

  const login = () => {
    let params = queryString.parse(props.location.search);
    let data = JSON.parse(atob(params["e"]));

    voiceboxer.api.post(
      "/users/login-otp",
      {
        email: data.email,
        literalId: data.literalId,
        otp,
        client_id: "vbx",
      },
      (err, data) => {
        if (err) {
          onLogin(err, null);
        }
        if (data) {
          const token = data;
          voiceboxer.token = token;
          voiceboxer.authenticated = true;
          token.validatedOTP = true;
          var COOKIE = config.cookie.name;
          Cookies.set(COOKIE, JSON.stringify(token));

          onLogin();
        }
      }
    );
  };

  const onLogin = (err) => {
    if (err) {
      setError(isSubmitting ? err : null);
      setIsSubmitting(false);
      setIsLoading(false);
    } else {
      var data = Cookies.get("login.data");
      if (data) {
        data = JSON.parse(atob(data));
        if (data.successRedirect) {
          document.location.href = data.successRedirect;
        }
      }
    }
  };

  const backToEmail = () => {
    document.location.href = "/login";
  };

  const renderError = () => {
    if (!error) return;

    var message = error.message;
    if (
      error.statusCode === 401 ||
      error.statusCode === 404 ||
      !error.statusCode
    )
      message = intl.get("Invalid credentials");
    else if (error.body && error.body.message) message = error.body.message;

    return <Bs.Alert bsStyle="danger">{message}</Bs.Alert>;
  };

  const handleChangeOTP = (otp) => {
    setOtp(otp);
  };

  const handleResendOTP = (event) => {
    event.preventDefault();
    let params = queryString.parse(props.location.search);
    const { email, literalId } = JSON.parse(atob(params.e));
    voiceboxer.api.post(
      "/users/checkEmail",
      {
        email: email,
        literalId,
        resend:true
      },
      (err, data) => {
        if (err) {
          setError(err);
        } else if (data.error) {
          setError({ statusCode: 200, body: { message: data.error === "Invalid e-mail entered" ? intl.get("Invalid e-mail entered") : (data.error === "E-mail is not registered in system" ? intl.get("E-mail is not registered in system") : data.error) } });
        }

        setIsShowMessage(true);
        let countDown = 30;

        let intervalCountDown = setInterval(() => {
          countDown = countDown - 1;
          setDisableResend(countDown);
          if (countDown === 0) {
            clearInterval(intervalCountDown);
          }
        }, 1000);
      }
    );
  };

  return (
    <DocumentTitle title="Login">
      <Loader loaded={!isLoading}>
        <Bs.Grid className="single-panel-container">
          <Bs.Row className="panel-row">
            <Bs.Col className='form-container'>
              <Bs.Panel className="panel-body">
                <div className='form-header'>
                  <div className='shapes-container'>
                    <div className="shape"/>
                    <div className="under-shape"/>
                  </div>
                  <h1>{"Login by OTP"}</h1>
                </div>
                <p style={{textAlign:'center'}}>{intl.get("Enter the one-time password sent to your email address")}</p>
                {renderError()}
                <form
                  onSubmit={(e) => {
                    handleLoginSubmit(e);
                  }}
                >
                  <OTPInput
                    autoFocus
                    isNumberInput
                    length={4}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={handleChangeOTP}
                  ></OTPInput>
                  <a className={disableResend ? 'resend-OTP button-disabled' : "resend-OTP"} href='' onClick={handleResendOTP} >{intl.get("Resend OTP")}{ disableResend>0&&('('+disableResend+')')}</a>
                  {isShowMessage>0&&(<p className='message-resend'>{intl.get("Email sent. If you need help contact support@voiceboxer.com")}</p>) /* ToDo Renaming: change support url to boost */ } 
                  <Bs.FormGroup className="text-align-center button-group-OTP">
                    <Bs.Button
                      type="button"
                      bsStyle="secondary"
                      onClick={() => {
                        backToEmail();
                      }}
                    >
                      {intl.get("Back")}
                    </Bs.Button>
                  </Bs.FormGroup>
                  <Bs.FormGroup className="text-align-center button-group-OTP">
                    <Bs.Button
                      type="submit"
                      disabled={isSubmitting || otp.length !== 4}
                      bsStyle="primary"
                    >
                      {intl.get("Login")}
                    </Bs.Button>
                  </Bs.FormGroup>
                </form>
                <div className="powered-by">
                  <span>{intl.get("Powered by")}</span>
                  <a href={config.rename.boostEventsWebsiteUrl}>
                    <img src="/img/BoostEvents-logos/portal-login-logo.png" />
                  </a>
                </div>
              </Bs.Panel>
            </Bs.Col>
          </Bs.Row>
        </Bs.Grid>
      </Loader>
    </DocumentTitle>
  );
}
