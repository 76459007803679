import React, {useState, useRef, useEffect} from "react";
import Cookies from 'cookies-js'
import {useSelector} from "react-redux";
import intl from "react-intl-universal";

function LanguageSelectionDropdown(props) {

  const supportedLocales = useSelector(state => state.languageDropdown.supportedLocales);
  const isHidden = useSelector(state => state.languageDropdown.isHidden);
  const [isActive, setActive] = useState(false);
  const languageList = useRef();
  const toggleButton = useRef();

  const onButtonClick = (e) =>{
    e.preventDefault();
    setActive(!isActive)
  };

  const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
      if (toggleButton.current && toggleButton.current.contains(e.target)) return
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

  const onSelectLocale = lang => {
    Cookies.set('lang', lang, {expires: 31622400});
    if (window.location.href.includes(window.location.search) && window.location.search !== '') {
      if (window.location.search.includes('lang')) {
        const url = new URL(window.location.href)
        const urlSearchParams = url.searchParams;
        urlSearchParams.set('lang', lang)
        url.search = urlSearchParams.toString();
        window.location.href = url.toString()
      } else {
        window.location.href = window.location.href + `&lang=${lang}`
      }
    } else {
      window.location.search = `?lang=${lang}`;
    }
    setActive(false)
  };
  const currentLocaleObject = supportedLocales.find(el => el.value === Cookies.get('lang').slice(0, 2));

  if (isHidden) {
    return null;
  }

  useOutsideClick(languageList, () => {
    setActive(false)
  });

  return (
    <div className='selector-container'>
      <button className={`selector-toggle-btn`} onClick={onButtonClick} ref={toggleButton}>
        <div className='flag-abbr'>{currentLocaleObject.flagAbbr}</div>
        <i className={isActive ? 'fa fa-angle-up' : 'fa fa-angle-down'}/>
      </button>
      {isActive &&
      (<ul id={'language-select-dropdown'} ref={languageList}>
        <li className='language-item selector-label'>{intl.get('Set interface language:')}</li>
        {supportedLocales.filter(locale => locale.status === 'published').map(locale => (
          <li key={locale.value}
              className={`language-item${currentLocaleObject.value === locale.value ? ' chosen' : ''}`}
              onClick={() => onSelectLocale(locale.value)}>
            <div className='flag-abbr'>{locale.flagAbbr}</div>
            <span>{locale.name}</span>
          </li>
        ))}
      </ul>)}
    </div>
  );
}

export default LanguageSelectionDropdown
