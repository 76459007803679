import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import DocumentTitle from "react-document-title"
import Loader from "react-loader"
import Cookies from 'cookies-js';
import queryString from 'query-string';
import intl from 'react-intl-universal';
import Recaptcha from '../../Components/Recaptcha';
import ProductStyle from "../../Components/ProductStyle";
import Translations from "../../Core/Translations";
import setCookieSameSite from '../../Utils/set-cookie-same-site';
import validateAccessApi from '../../Utils/validate';

var voiceboxer = require("../../Core/Libs/voiceboxer-api-client");
var COOKIE = config.cookie.name;

var Bs = require('react-bootstrap');

export default function Login(props) {

    const history = useHistory()

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState("");
    const [product, setProduct] = useState("");

    const token = useRef(null);
    const refreshToken = useRef(() => {});

    useEffect(() => {

      setRedirectCookie();
      setProductExists();
      checkToken();      

    }, []);

    useEffect(() => {

        if (isSubmitting) {
            login(email)
        }

    }, [isSubmitting])

    const checkToken = async () => {

      const token = Cookies.get(COOKIE);
      setIsLoading(true)  
      if (token) {             
        voiceboxer.api.get("/users/me", async (err, user) => {
          if (user) {
            const productCookie = JSON.parse(token);
            await validateAccessApi("product", productCookie.access_token);
            alreadyLoggedIn();
          } else {
            const validateSuccess = await validateAccessApi("access");           
            if (!validateSuccess) {
              setIsLoading(false)
            }            
          }
        });
      } else {
        const validateSuccess = await validateAccessApi("access");
        if (!validateSuccess) {
          setIsLoading(false);  
        }
      }

    }

    const setProductExists = () => {

      let params = queryString.parse(props.location.search)
      if (params["p"]) {
          setProduct(params["p"]);
      }

    }

    const changeThemeIfNeeded = () => {

      let params = queryString.parse(props.location.search)
      if (params["p"]) {
        const product = params["p"];
        Cookies.set('login.product', params["p"]);
        ProductStyle.addCss(product)
      } else {
        ProductStyle.checkAndAddStyle();
         // Cookies.expire('login.product');
      }
    }

    const setRedirectCookie = () => {

        let params = queryString.parse(props.location.search)
        let cookie = Cookies.get('login.data')

        if (params["t"]) {
          setCookieSameSite('login.data', params["t"]);
        } else if (!cookie) {
            Cookies.set('login.data', btoa(JSON.stringify({"successRedirect":`${config.access.url}`})));
        }

    }

    const handleLoginSubmit = (e) => {
        e.preventDefault()
        setIsSubmitting(true)
    }

    const alreadyLoggedIn = () => {

      var data = Cookies.get('login.data');

      if (data) {
          data = JSON.parse(atob(data))      
          if (data.successRedirect) {
              document.location.href = data.successRedirect;
          }
      }

    }

    const login = async (email) => {

        if (!token.current) {         
          return setTimeout(() => {
            login(email);
          }, 500);
        }        

        let params = queryString.parse(props.location.search);
        let literalId = undefined;
        var languageRegex = /(([^<>(){}\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/mg;
        if (!languageRegex.test(email)) {
          setIsSubmitting(false);
          return setError({
            message: 'Email is invalid'
          })
        }
        if (params.t) {
          const option = JSON.parse(atob(params.t));
          literalId = option.literalId;
        }

        try {
          const response = await fetch(`${config.accessApi.url}/user/checkEmail`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: email,
              token: token.current,
              literalId,
              region: config.region
            })
          });

          const data = await response.json();

          if (data.error) {
            refreshToken.current(data.captchaVer);
            setError({ statusCode: 200, body: { message: data.error === "Invalid e-mail entered" ? intl.get("Invalid e-mail entered") : (data.error === "E-mail is not registered in system" ? intl.get("E-mail is not registered in system") : data.error) }  });
          } else if (data.redirect) {
            if (data.updateRedirectUrl) {
              setCookieSameSite('login.data', btoa(JSON.stringify({"successRedirect":`${data.accessUrl}`})));
            }
            document.location.href = `${data.redirect}&p=${product}`;
          }

        } catch (err) {
          console.log("erroras", err)
          refreshToken.current();
          setError(err);
        }

        setIsSubmitting(false);

    };

    const onLogin = (err) => {

        if (err) {
              setError(isSubmitting ? err : null)
              setIsSubmitting(false)
              setIsLoading(false)
        }

    }

    const renderError = () => {

        if(!error) return;

        var message = error.message;
        if(error.statusCode === 401 || error.statusCode === 404 || !error.statusCode) message = intl.get('Invalid credentials');
        else if(error.body && error.body.message) message = error.body.message;

        return (
            <Bs.Alert bsStyle='danger'>
                {message}
            </Bs.Alert>
        );
    }

    const handleChangeEmail= (event) => {
      var email = event.target.value.replace(/\s/g, '');
      setEmail(email)
    }

    return (
        <DocumentTitle title='Login'>
            <Loader loaded={!isLoading}>
                <Bs.Grid className='single-panel-container'>
                    <Bs.Row className='panel-row'>
                        <Bs.Col className='form-container'>
                            <Bs.Panel className='panel-body'>
                                <div className='form-header'>
                                  {ProductStyle.getLogo(product)}
                                <h1>{intl.get("Login")}</h1>
                              </div>
                                {renderError()}

                                <form onSubmit={(e) => {
                                    handleLoginSubmit(e)
                                }}>
                                    <Bs.FormGroup>
                                        <Bs.FormControl type='text' name={"username"} placeholder={intl.get('E-mail')} value={email} onChange={handleChangeEmail} autoFocus autoComplete={"username"} />
                                    </Bs.FormGroup>

                                    <Recaptcha onToken={(recaptchaToken) => {
                                      token.current = recaptchaToken
                                    }} refreshToken={(onRefresh) => {
                                      refreshToken.current = onRefresh
                                    }} className={"captcha-container"}/>

                                    <Bs.FormGroup className='text-align-center'>
                                        <Bs.Button type='submit' disabled={isSubmitting} bsStyle='primary'>
                                            {intl.get('Continue')}
                                            </Bs.Button>&nbsp;
                                    </Bs.FormGroup>
                                    <div className='text-align-center'>
                                      <span><a href={`/forgot?p=${product}`} className={"boldLink signup-link"}>{intl.get('Forgot password')}</a></span>
                                    </div>
                                </form>
                                <div className={'signUpBlock'}>
                                  <span>{Translations.get('Do not have an account?')}</span> &nbsp;
                                  <a href={`${window?.config?.access?.url}/sign-up-1?p=${product}`} style={{"whiteSpace":"nowrap"}} className="signup-link">{intl.get('Sign up')}</a>
                                </div>
                            </Bs.Panel>
                        </Bs.Col>
                    </Bs.Row>
                </Bs.Grid>
            </Loader>
        </DocumentTitle>
    )

}
